import Api from "@/config/Api";

export default {
  getStablishments() {
    return Api().get("/me/establishments", {});
  },
  addStablishments(data) {
    return Api().post("/me/establishments", data);
  },
  updateStablishments(data) {
    return Api().put(`/me/establishments/${data.id}`, data);
  },
  deleteStablishmentId(data) {
    return Api().delete(`/me/establishments/${data.id}`, {});
  },
  setStablishmentDefault(data) {
    return Api().post(`/me/establishments/default`, data);
  },
  setEmissionPointDefault(data) {
    return Api().post(`/me/emission-point/default`, data);
  },
  getEmissionPoints() {
    return Api().get("/me/emission-point", {});
  },
  addEmissionPoints(data) {
    return Api().post("/me/emission-point", data);
  },
  updateEmissionPoints(data) {
    return Api().put(`/me/emission-point/${data.id}`, data);
  },
  deleteEmissionPointId(data) {
    return Api().delete(`/me/emission-point/${data.id}`, {});
  },
  getIntegrationsWoocommerce(data) {
    return Api().get(`/integrations/woocommerce`, data);
  },
  addIntegrationsWoocommerce(data) {
    return Api().post(`/integrations/woocommerce`, data);
  },
  SyncIntegrationsWoocommerce(data) {
    return Api().post(`/integrations/woocommerce/batch`, data);
  },
  getIntegrationsPayment(data) {
    return Api().get(`/integrations/payment`, data);
  },
  addIntegrationsPayment(data) {
    return Api().post(`/integrations/payment`, data);
  }
};
